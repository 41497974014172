<template>
  <div class="w100p pl10 pr10" >
   <div class="textc mb20">
     <p class="textc fs9  fwbold">分类模块</p>
   </div>

   <p class="fs8 mt10 mb10 fwbold">
      展示样式
    </p>
    <div class="d-flex flex-wrap-y">
      <div class="ml10" v-for="(item,index) in listData.list" :key="index" @click="curIndex = index">
        <img :src="item.img" v-if="item.img" class="w70 h70 ml10 mr10">
      </div>
    </div>
    <div class="mt10">
<!--       <el-form>-->
<!--         <el-form-item label="图标设置">-->
<!--           <div class="">-->
<!--             <el-upload-->
<!--                 accept="image/jpeg,image/png"-->
<!--                 class="addTabIconTap"-->
<!--                 :action="uploadUrl"-->
<!--                 :headers="upParams"-->
<!--                 :show-file-list="false"-->
<!--                 :on-success="bannerSuccess"-->
<!--                 :before-upload="beforeUploadImageDynamicPic">-->
<!--               <img v-if="currentTab.img" :src="currentTab.img" class="w100p h100p">-->
<!--               <i v-else class="el-icon-plus"></i>-->
<!--             </el-upload>-->
<!--             <p class="fs6 mb10" v-if="size">图标上传宽度为{{size}}px的效果最佳</p>-->
<!--             <p class="fs6 mb10" v-else>图标上传宽度为100*100px的效果最佳</p>-->
<!--           </div>-->
<!--         </el-form-item>-->
<!--         <el-form-item label="标题设置">-->
<!--            <el-input :placeholder="currentTab.title" v-model="currentTab.title" maxlength="4" show-word-limit class="w200"></el-input>-->
<!--         </el-form-item>-->
<!--         <url-setting></url-setting>-->
<!--       </el-form>-->

      <pic-manager :img-url="shopTabList[curIndex].img" :to-url="shopTabList[curIndex].toUrl">
         <template v-slot:top>
             <el-form-item>
               <el-form-item label="标题设置">
                 <el-input :placeholder="shopTabList[curIndex].title" v-model="shopTabList[curIndex].title" maxlength="4" show-word-limit class="w200"></el-input>
               </el-form-item>
             </el-form-item>
         </template>
      </pic-manager>
    </div>
    <div class="ml20">
      <el-button type="primary" class="" @click="reset_tab_tap">
        图标重置
      </el-button>
    </div>


  </div>
</template>

<script>
let token = localStorage.getItem("token")
import UrlSetting from "@/pages/shop/component/UrlSetting";
import PicManager from "@/pages/shop/component/PicManager";
import {shopTabList} from "@/utils/shop";


export default {
  components:{PicManager},
  props:['listData'],
  data() {
    return {
      shopTabList: shopTabList,
      curIndex: 0,
      upParams: {
        token: token
      },
      currentTab:'',
      optionList:[{
        name:'无'
      }],
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      oldTab:''
    };
  },
  watch:{
    listData:{
      handler(val){
        console.log(val)
        this.oldTab = JSON.parse(JSON.stringify(val))
        this.currentTab = val.list[0]
      },
      immediate:true
    }
  },
  created(){

  },
  methods:{
    select_tap(e){
      let than = this
      than.optionList.forEach(item=>{
        console.log(item)
        if(item.name == than.currentTab.name){
          than.currentTab.goodstypeId = item.goodstypeId
          item.disabled = true
        }
      })
      console.log(this.currentTab)
    },
    bannerSuccess(res){
      this.currentTab.img = this.ossUrl + '/' + res.data
    },
    reset_tab_tap(){
      this.optionList.forEach(item=>{
        item.disabled = false
      })
      this.$emit('reset_tab_tap',this.oldTab)
    }
  }
};
</script>

<style lang="scss" scoped>
 /deep/.addTabIconTap .el-upload--text{
  width: 70px;
  height: 70px;
  line-height: 70px;
}
/deep/ .addTabIconTap .el-upload-list__item {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
</style>
