<template>
    <el-form-item label="跳转">
      <el-cascader
          :value="curType"
          :options="urlList"
          @change="handleChange"
          placeholder="请选择"
      ></el-cascader>
    </el-form-item>
</template>
<script>
import {get} from "@/utils/request";

export  default {
  props:{
    curType:{
      type: Array
    },
    cateList:{
      type: Array
    },
    goodGroupList:{
      type: Array
    },
    type: {
      type: [String, Number]
    }
  },
   data(){
     return {
       urlList: [
         {label:'分类',value: 'cate',children:[]},
         {label: '商品组',
          value: 'good_group',
          children: [
           {label:'秒杀',value:'skill'},
           {label:'拼团',value: 'group'},
         ]},
         {label: '页面',value: 'page',
          children: [{label:'领券中心',value:'coupon'
         }]},
       ]
     }
   },
   mounted(){
      this.initTypeList(); // 分类
      this.initGroupList(); // 分组

   },
   methods:{
      initTypeList(){
         get('/goods/typeName').then(res=>{
           this.urlList[0].children=res.map(item => {
             return {
               label: item.label,
               value: item.id
             }
           })
         })
      },
     initGroupList(){
       let params = {
        companyId: JSON.parse(localStorage.getItem('info')).companyId,
        pageNum: 1,
        pageSize: 999,
      }
       get('/good/group/list',).then(res => {
         if(res.list.length) {
          let data = res.list.map(item => {
            return {
              ...item,
              label: item.name,
              value: item.id
            }
          })
          this.urlList[1].children = this.urlList[1].children.concat(data)
          if(this.type && this.type === 1) { // 只显示分组选项
              this.urlList = [this.urlList[1]]
          }

         }

       })


     },
     handleChange(e){
        this.$emit('urlChange',e)
     }
   }
}
</script>
<style>
</style>
