<template>
  <div>
     <el-form label-width="80px">
       <slot name="top"></slot>
       <el-form-item label="图标设置">
         <template>
           <img-upload @imgChange="imgChange" :img-url="item.img" :size-type="sizeType"/>
         </template>
       </el-form-item>
         <el-form-item label="标题" v-if="showText">
             <el-input :value="item.title" @input="titleChange"/>
         </el-form-item>
       <url-setting @urlChange="urlChange" :cur-type="item.set" v-if="!hiddenToUrl"></url-setting>
       <slot name="bottom"></slot>
     </el-form>
  </div>
</template>
<script>
import UrlSetting from "@/pages/shop/component/UrlSetting";
import ImgUpload from "@/pages/shop/component/ImgUpload";

export  default {
  components: {ImgUpload, UrlSetting},
  props:{
      item:{
        type: Object
      },
      showText:{
          type: Boolean
      },
     imgUrl: {
       type: String
     },
    toUrl:{
      type: Array
    },
    sizeType:{
      // 0正方形 1 3:2
      type: Number,
      default: 0
    },
    hiddenToUrl: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {

    }
  },
  methods:{
    titleChange(e){
        this.$emit('valueChange','title',e);
    },
    imgChange(url){
       this.$emit('valueChange','img',url);
    },
    urlChange(arr){
      // console.log('urlgaib',url)
      //  this.$emit('urlChange', arr, index)
      // this.$emit('valueChange','url',url);
      this.$emit('urlChange',arr)
    }
  }
}
</script>
