<template>
  <div>
    <el-upload
        accept="image/jpeg,image/png"
        :class="sizeType===0?'up-box':'up-box32'"
        :action="upUrl"
        :headers="upHeader"
        :show-file-list="false"
        :on-success="upSuccess"
        :before-upload="beforeUpload">
      <img v-if="imgUrl" :src="ossUrl+imgUrl" class="w100p h100p">
      <i v-else class="el-icon-plus"></i>
    </el-upload>
    <p class="fs6 mb10">建议尺寸：100*100px</p>
  </div>
</template>
<script>
let token = localStorage.getItem("token")
export  default {
  components: {},
  props:{
    imgUrl:{

    },
    sizeType:{
      // 0正方形 1 3:2
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      upUrl: this.$store.state.uploadingUrl,
      upHeader: {token:token},
      ossUrl:this.$store.state.ossUrl,
    }
  },
  methods:{
    upSuccess(res){
       console.log('上传成功',res)
       this.$emit('imgChange',res.data)
    },
    beforeUpload(file){
      const isImg = file.type === 'image/jpeg'||file.type==='image/png';
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isImg) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 1MB!');
      }
      return isImg && isLt2M;
    }
  }
}
</script>
<style scoped>

/deep/ .up-box .el-upload--text{
  width: 70px;
  height: 70px;
  line-height: 70px;
}

/deep/ .up-box32 .el-upload--text{
  width: 150px;
  height: 100px;
  line-height: 70px;
}
</style>
