export  let shopTabList=[{
    img:'/yimai_photos/departmentStore/tab1.png',
    title:'个人护理',
    set: [],
},{
    img:'/yimai_photos/departmentStore/tab2.png',
    title:'家务清洁',
    set: [],

},{
    img:'/yimai_photos/departmentStore/tab3.png',
    title:'母婴用品',
    set: [],

},{
    img:'/yimai_photos/departmentStore/tab4.png',
    title:'防护用品',
    set: [],

},{
    img:'/yimai_photos/departmentStore/tab5.png',
    title:'家纺用品',
    set: [],

},{
    img:'/yimai_photos/departmentStore/tab6.png',
    title:'休闲零食',
    set: [],

},{
    img:'/yimai_photos/departmentStore/tab7.png',
    title:'限时秒杀',
    set: [],

},{
    img:'/yimai_photos/departmentStore/tab8.png',
    title:'拼团抢购',
    set: [],

}]

let bannerData={
    list:[
        {
            img:'/yimai_photos/departmentStore/banner1.png',
            set: [],
        }
    ]
}


let diy={
    list:[
        {
            img:'/yimai_photos/departmentStore/goodsA1.png',
            title:'防疫口罩',
            set: [],
        },
        {
            img:'/yimai_photos/departmentStore/goodsA2.png',
            title:'迷你雨伞',
            set: [],
        },
    ],
    title:'今日推荐',
    type:'diy',
}

let goodList={
    type:'seckill',
    title:'限时秒杀',
    goodType: 0,
    styleType: 0
}
let notice = [{text: '商城上线了'},{text: '商城上线了1'}]
export  let defaultShopData=[
    {type: 'search',data:{list: [
        {'img': '/sou.png'}
    ]},},
    {type: 'tab',data: {list: shopTabList},},
    {type: 'notice', data: notice},
    {type: 'banner',data: bannerData,},
    {type: 'diy',data: diy},
    {type: 'group',title: '限时秒杀',data:{type: 1,style: 0, id: 'skill',good:[]}, set:[]},
    {type: 'group',title: '团购抢购',data:{type: 2,style: 0, id: 'group',good:[]}, set:[]},
    {type: 'group',title: '精品推荐',data: {type: 3,style: 0,id: '', good:[], set:[]}}
]
